  let required = (propertyType)=>{
    //v => !!v || 'Se requiere E-mail',
      return v=> v && v.length >0 || `Se necesita el campo ${propertyType}`
  }

  // let validField = (propertyType)=>{
  //   // return v=>(v == /.+@.+\..+/.test(v) && v.length <1) || `${propertyType} invalido ${v.length}`
  //   return v=>v.length <1 || `${propertyType} invalido ${v.length}`
  // }
  
  let accept = (propertyType)=>{
      return v => !!v || `Debes aceptar ${propertyType}`
  }

  let accept2 = (propertyType)=>{
    return v => !v.checked || `Debes aceptar ${propertyType}`
  }


  let invalidEmail = (propertyType)=>{
    // return v=>(v == /.+@.+\..+/.test(v) && v.length <1) || `${propertyType} invalido ${v.length}`
    //return v=>v.length <1 || `${propertyType} invalido ${v.length}`
    // return v => /^[^@]+@\w+(\.\w+)+\w$/.test(v) || `${propertyType} invalido ${v.length}`
    return v => /.+@.+\..+/.test(v)  || `${propertyType} invalido ${v.length}`
  }

  let minLength = (propertyType, minLength)=>{
    return v=> (v && v.length >= minLength) || `El campo ${propertyType} debe ser mayor de ${minLength} caracteres`
  }

  let maxLength = (propertyType, maxLength)=>{
    return v=> (v && v.length <= maxLength) || `El campo ${propertyType} debe ser menor de ${maxLength} caracteres`
  }

  let onlyNumeric = (propertyType)=>{        
    return v=> /\d/g.test(v) || `El campo ${propertyType} debe ser numerico`
    // return v=> /[0-9]/g.test(v) || `El campo ${propertyType} debe ser numerico`
  }

  let NoComas = (propertyType)=>{        
    return v=> /\d/g.test(v) || `El campo ${propertyType} debe ser numerico`
    // return v=> /[^,]/g.test(v) || `El campo ${propertyType} no debe tener comas` //Nosirve
  }

  let onlyLetter = (propertyType)=>{
    return v=> /[^0-9]/g.test(v) || `El campo ${propertyType} debe ser letras`
  }

  let dateFormat = (propertyType)=>{
    return v=> v && v.length >0 || `Se necesita el campo ${propertyType}`
    //Formato dd-mm-yyyy
    // return v=> /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/](?:0?[1-9]|1[0-2])|(?:29|30)[/](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/]0?2[/](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/.test(v) || `Se necesita el campo ${propertyType}`
    
    // pattern="(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}"
    // return v=> v == Date().toLocaleDateString() || `El campo ${propertyType} no tiene formato válido`
    // return v=> v.date = 'dd/mm/yyyy' || `El campo ${propertyType} no tiene formato válido`
  }

  //var letters = /^[A-Za-z]+$/;
//   if(inputtxt.value.match(letters))
//      {
//       return true;
//      }

export default{
    required,
    dateFormat,
    minLength,
    maxLength,
    invalidEmail,
    onlyNumeric,
    onlyLetter,
    NoComas,
    accept2,
    accept
}