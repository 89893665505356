<template>
 <div id="saldo">
  <v-row v-for="(saldo,index) in saldo" :key="index">                    
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">                       
            <v-card color="#385F73" dark>
              <v-card-title class="text-h3"><b> {{saldo.saldo | currency }}</b> </v-card-title>   
              <v-card-subtitle>Saldo Actual</v-card-subtitle>              
            </v-card>
      </v-col>                   
          
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
        <v-card class="teal" dark>            
            <v-card-title class="text-h5"><b> {{saldo.salesorders | currency }}</b> </v-card-title>
            <v-card-subtitle><v-icon>add_shopping_cart</v-icon> Entrada </v-card-subtitle>
            <!--Modal SalesOrder-->  
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }"> 
                    <v-btn
                      color="success"
                      v-bind="attrs"
                      v-on="on"
                      @click="dialogSalidas=true"
                    >
                     Detalles
                    </v-btn>                
                  </template>
                  <span>Detalle de Ventas</span>
                </v-tooltip>
              </template>   
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
        <v-card class="red" dark>            
            <v-card-title class="text-h5"><b> {{saldo.purchases | currency }}</b> </v-card-title>               
            <v-card-subtitle><v-icon>payment</v-icon> Compras</v-card-subtitle> 
             <!--Modal Purchases-->  
             <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }"> 
                    <v-btn
                      color="warning"
                      v-bind="attrs"
                      v-on="on"
                      @click="dialogPurchase=true"
                    >
                     Detalles
                    </v-btn>                
                  </template>
                  <span>Detalle de Compras</span>
                </v-tooltip>
              </template>                 
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
        <v-card class="pink" dark>            
            <v-card-title class="text-h5"><b> {{saldo.expenses | currency }}</b> </v-card-title>   
            <v-card-subtitle><v-icon>attach_money</v-icon> Gastos</v-card-subtitle>    
            <!-- Modal Expenses-->  
            <template>  
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }"> 
                    <v-btn
                      color="pink"
                      v-bind="attrs"
                      v-on="on"
                      @click="dialogExpensive=true"
                    >
                     Detalles
                    </v-btn>                
                  </template>
                  <span>Detalle de Gastos</span>
                </v-tooltip>
              </template>   
            <!-- FIN Modal Expenses-->            
        </v-card>
      </v-col>
  </v-row>

  <!-- Detalle Salida -->
  <v-dialog
                        v-model="dialogSalidas"
                        persistent
                        max-width="800px"                 
                      >                      
    <v-card>
      <v-card-title>
                              <span class="headline">Detalle de Ventas</span>
                              <v-btn
                                          class="headline"
                                          color="blue darken-1"
                                          text
                                          @click="dialogSalidas = false"
                                        >
                                  <v-icon>close</v-icon>
                              </v-btn>
      </v-card-title>
      <v-card-text>
          <v-container v-if='this.dialogSalidas==true'>
            <SalesOrderItem :salesorders="salesorders"  />
          </v-container>    

                                    
                <hr>
                              <v-btn
                                color="default"
                                v-bind="attrs"
                                v-on="on"
                                @click="dialogSalidas=false"
                                text
                              >
                                Cerrar
                              </v-btn>   
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>              
        </v-card-actions>
    </v-card>                    
  </v-dialog>
  <!-- FIN Detalle Salida -->


  <!-- Detalle Purchase -->
  <v-dialog
                        v-model="dialogPurchase"
                        persistent
                        max-width="800px"                 
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="white"            
                            v-bind="attrs"
                            v-on="on"
                            plain
                          >
                           Detalles
                          </v-btn>
                        </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">Detalle de Compras</span>
                              <v-btn
                                          class="headline"
                                          color="blue darken-1"
                                          text
                                          @click="dialogPurchase = false"
                                        >
                                          <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <v-container v-if='this.dialogPurchase==true'>
                                <!-- Busqueda Purchase -->
                                  <PurchaseItem :purchases="purchases"  />
                                <!-- FIN Busqueda Purchase -->
                              </v-container>
                              <v-btn
                                color="default"
                                v-bind="attrs"
                                v-on="on"
                                @click="dialogPurchase=false"
                                text
                              >
                                Cerrar
                              </v-btn>  
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>              
                            </v-card-actions>
                          </v-card>                    
  </v-dialog>
  <!-- Fin Detalle Purchase -->


  <!-- Detalle Expensive -->
  <v-dialog
                        v-model="dialogExpensive"
                        persistent
                        max-width="800px"                 
                      >                        
                          <v-card>
                            <v-card-title>
                              <span class="headline">Detalle de Gastos</span>
                              <v-btn
                                          class="headline"
                                          color="blue darken-1"
                                          text
                                          @click="dialogExpensive = false"
                                        >
                                          <v-icon>close</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text>
                              <v-container v-if='this.dialogExpensive==true'>
                                <!-- Busqueda Expensive -->
                                  <ExpensiveItem :expensives="expensives"  />
                                <!-- FIN Busqueda Expensive -->
                              </v-container>
                              <v-btn
                                color="default"
                                v-bind="attrs"
                                v-on="on"
                                @click="dialogExpensive=false"
                                text
                              >
                                Cerrar
                              </v-btn>                                
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>              
                            </v-card-actions>
                          </v-card>                    
  </v-dialog>
  <!-- FIN Detalle Expensive -->


 </div>
</template>


<!-- Dialog para agregar --> 

<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
// import router from '@/router'
import Dayjs from 'dayjs';
import es from "dayjs/locale/es"
import SalesOrderItem from "@/components/SalesOrderItem";
import PurchaseItem from "@/components/PurchaseItem";
import ExpensiveItem from "@/components/ExpensiveItem";


export default{
  name: "saldo",  
  computed:{
        ...mapState(['saldo','salesorders','purchases','expensives'])
  },
  components: {    
    SalesOrderItem,
    PurchaseItem,
    ExpensiveItem    
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    formatDate(dateString) {
            Dayjs.locale(es)
            const date = Dayjs(dateString);
            return date.format('dddd MMMM D, YYYY')
    }, 
    async getSaldo(){          
      try{                
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadSaldo',this.attribute);  
        await this.$store.dispatch('loadSalesOrders2',this.attribute);
        await this.$store.dispatch('loadPurchases2',this.attribute);
        await this.$store.dispatch('loadExpensives2',this.attribute);       

      }catch(error){
       console.log('Saldo '+error);
      }  finally{
        this.hiddenLoading()
      } 
    },    
  },
  created(){
    this.getSaldo()    
  },
  data(){
      return{      
        attribute: {sword: '',limit: 27, date_start:'',date_end:''},
        editRow:null,
        dialogSalidas:false,
        dialogPurchase:false,
        dialogExpensive:false,
        theader: [
          { text: 'Accion', value: 'customername' },
          { text: '#', value: 'customername' },
          { text: 'ID', value: 'customername' },
          { text: 'Cliente', value: 'customername' },
          { text: 'Subtotal', value: 'subtotal_quote' },
          { text: 'Descuento', value: 'subtotal_quote' },
          { text: 'Subtotal c/Desc', value: 'subtotal_quote' },
          { text: 'IVA', value: 'iva_quote' },
          { text: 'Total', value: 'total_quote ' },       
          { text: 'Status', value: 'total_quote ' },       
          { text: 'Salida', value: 'iva_quote' },
          { text: 'Registro', value: 'iva_quote' },
          { text: 'Actualizacion', value: 'iva_quote' },
        ],
        theaderPurchases: [
          {
            text: 'Descripción',
            align: 'start',
            sortable: false,
            value: 'id',
          },        
          { text: 'Costo Envío', value: 'shipping_cost' },
          { text: 'Otro Costo', value: 'other_cost' },
          { text: 'Costo Aduana', value: 'aduana_cost' },
          { text: 'Comision', value: 'comision' },
          { text: 'Tipo de Cambio', value: 'currencytipocambio' },
          { text: 'Costo Productos', value: 'cost_product' },
          { text: 'IVA', value: 'iva' },
          { text: 'Costo Total', value: 'totalpurchase' }        
        ],
        theaderExpensive: [
          { text: 'Descripcion', value: 'branchname' },
          { text: 'Price', value: 'price' },
          { text: 'Iva', value: 'iva' },
          { text: 'Total', value: 'total' },
          { text: 'Fecha Compra', value: 'date_buy' },
          { text: 'Notas', value: 'notes' }
        ],
        //sfecha:new Date().toISOString().substr(0,10)
      }
  }  
}
</script>