<template>
<div id="ProductView">  
  <v-card-text>
                             <v-container>
                                <v-row>
                                  <v-col cols="12">
                                    <table class="table table-striped">
                                      <tr>
                                        <td colspan="2">
                                          <h5>
                                            <b> {{productInfo.description}}</b>
                                          </h5>
                                        </td>                                        
                                      </tr>
                                      <tr>
                                        <td>
                                        <div v-if='productInfo.filename == ""'>
                                          <v-img
                                              lazy-src="https://www.ionoff.com.mx/wp-content/uploads/2019/07/iOnOff_logo_100x100.png"
                                              height="200"
                                              src="https://www.ionoff.com.mx/wp-content/uploads/2019/07/iOnOff_logo_100x100.png"
                                            ></v-img>                                    
                                          </div> 
                                          <div v-else>
                                            <v-img
                                            v-bind:lazy-src="productInfo.filename"
                                            max-height="200"
                                            width="200"
                                            v-bind:src="productInfo.filename"
                                            ></v-img>
                                          </div>   
                                          <div>
                                            <!-- <BarcodeGenerator
                                              :value="productInfo.id"
                                              :format="'CODE39'"
                                              :lineColor="'#000'"
                                              :width="4"
                                              :height="20"
                                              :elementTag="'img'"
                                              :displayValue=false
                                              /> 
                                              -->
                                            <img src="" id="barcodeView" alt="" />
                                            <br>  
                                            <b>SKU: </b>{{productInfo.id}} <br>
                                            <b>CLAVE SAT: </b>{{productInfo.clave_sat}}  <br>
                                            <b>MODELO: </b>{{productInfo.modelo}}
                                            <br><br>
                                            <div>
                                              <b>Categoria:</b> {{productInfo.category}} <br>
                                              <b>Tipo de Articulo:</b> {{productInfo.type_article}} <br>
                                              <b>Status:</b> {{productInfo.status}}
                                            </div>                                       
                                          </div>
                                      </td>
                                      <td>
                                        <div>
                                          <h6>PRECIOS</h6>
                                          <div><b>iCheck: </b>{{productInfo.price | currency }} 
                                            <!-- {{pricelist.currencyabraviada}} -->
                                          </div>
                                          <!-- <div v-if='pricelist.price_mayorista != null' style="text-align:left;">
                                          Mayorista: {{pricelist.price_mayorista | currency }}<small>MXN</small>
                                        </div> -->
                                          <div><b>Mayorista: </b>{{productInfo.price_mayorista | currency }} MXN </div>
                                          <div><b>Minorista: </b>{{productInfo.price_minorista | currency }} MXN <small>{{productInfo.currencyabraviada}}</small></div>      
                                          <div><b>Regular: </b>{{productInfo.price_regular | currency }} MXN </div>
                                          <div><b>Publico: </b>{{productInfo.price_publico | currency }} MXN </div>
                                          <br><br>
                                          <h6>STOCK</h6>
                                          <div><b>iCheck: </b>{{productInfo.stock }} </div>
                                          <div><b>Syscom: </b>{{productInfo.stock_syscom }} </div>
                                          <div><b>CTOnline: </b>{{productInfo.stock_ctonline }} </div>
                                          <div><b>CVA: </b>{{productInfo.stock_cva }} </div>
                                          <div><b>Camino: </b>{{productInfo.camino }} </div>
                                          <br><br>  
                                          <div>
                                            <h6>PLUGINS</h6>
                                            <b>CTOnline ID:</b> {{productInfo.ctonline_id }} <br>
                                            <b>CVA ID:</b> {{productInfo.cva_id }} <br>
                                            <b>MELI ID: </b> {{productInfo.meli_id }} <br>
                                            <b>SYSCOM ID:</b> {{productInfo.syscom_id }} <br>
                                            <b>WOO ID:</b> {{productInfo.woo_id }} 
                                          </div>
                                          <br><br>
                                        </div>                                              
                                        </td>
                                      </tr>
                                     
                                    </table>  
                                  </v-col>              
                                </v-row>
                                <v-row >
                                  <v-col cols="12" class="my-4">
                                  <!-- Botones Acciones -->
                                  <v-btn-toggle
                                          v-model="toggle_exclusive"
                                          mandatory
                                        >
                                  <v-btn
                                                  v-if='productInfo.url != null'
                                                  color="deep-white default"
                                                  v-bind:href="productInfo.url"
                                                  target="_blank"
                                                >                
                                                <v-icon>public</v-icon>
                                              </v-btn>   

                                              <v-btn
                                                  color="deep-black"
                                                  v-bind:href="url(productInfo.id)"
                                                  target="_blank"
                                                  >                
                                                  <v-icon>view_list</v-icon>
                                                </v-btn>
                                      </v-btn-toggle>                                                                                                                                
                                <!-- FIN Botones Acciones -->
                                  </v-col>
                                  <v-col cols="12" justify="end">
                                <hr>

                                    <small><b>Registro: </b> {{productInfo.created_at}} </small><br>
                                    <small><b>Actualización: </b> {{productInfo.updated_at}}</small>    
                                  </v-col>
                               </v-row>
                            </v-container>
                          </v-card-text>
  
</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
import baseApi from '@/services/baseApi';
import JsBarcode from 'jsbarcode';
import router from '@/router';

export default {
  name: 'ProductView',  
  components: {
    // BarcodeGenerator
  },  
  computed:{
    // 'products',
    ...mapState(['']) 
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    async getPrice2(){     
    },
    url: function (href){
        return 'detalleventasproducto?pricelistid='+ href;
    },    
    getDataProduct(pricelistID) {
          try{        
            this.showLoading({titulo:"Accediendo información del Cliente",color:"warning"})
            baseApi().get(`precios/${pricelistID}`).then((response)=>{
              if(!response.data.error){
                this.productInfo=response.data;       
                JsBarcode("#barcodeView", response.data.id, {
                  // format: "pharmacode",
                  // lineColor: "#0aa",
                  width: 2,
                  height: 40,
                  displayValue: false
                });                      
                //this.$swal("Genial!",response.data.message, "success");
                // this.dialog10=true;
              }else{
                this.$swal("Error!",response.data.message, "error");
              }                                  
              //console.log("VENTA: "+response.data.message);
            });
          }catch(error){
            // console.log(error);
            if (error.message=='Request failed with status code 500') {
              this.$swal("Error!",error.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
            }else if(error.message=='Request failed with status code 401'){
              this.$swal("Error!", "Ha terminado la sesion <br/>Por seguridad ingrese de nuevo sus credenciales", "error");
              router.push('/login');
            }else{
              this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error, "error");
            } 
          }finally{
            this.hiddenLoading()
          } 

      
      },
  },
  props: {
    // productInfo:{
    //   type:Object,
    //   required:true
    // },
    productid: Number,
  },
  data(){
     return{
        // valid: false,
        // ...validations,
        toggle_exclusive:'',
        attribute: {sword: 'All',limit: 27},
        productInfo:{},
        
      
        
     }
  },
  destroyed() {
    console.log("Componente eliminado ProductView");
  },
  mounted(){
    console.log("Montado Product View");
    if (this.productid>0) {
      this.getDataProduct(this.productid)
    }
  },
  created(){
    // this.getPrice2()    
  }  
}
</script>