<template>
 <div id="kpis" class="text-center">

  <v-card
    class="mx-auto"
    max-width="344"
  >
    <v-card-text>
      <div>Total de Registros </div>
      <!-- Mensuales -->
      <br>
      
      <div class="text--primary">
        <v-progress-linear
          color="primary"
          height="25"
          :value="kpis.qvalue"          
          striped
        >
        <strong style='color:white;'> {{ kpis.qvalue }} </strong>
        </v-progress-linear>
        <p class="text-left"><strong> {{ kpis.qvalue }} </strong> Cotizaciones </p>
        <br>

        <v-progress-linear
          color="teal"
          height="25"
          :value="kpis.svalue"          
          striped
        >
        <strong style='color:white;'> {{ kpis.svalue }} </strong>
        </v-progress-linear>
        <p class="text-left"><strong> {{ kpis.svalue }} </strong> Ventas</p>
        <br>

        <v-progress-linear
          color="red"
          height="25"
          :value="kpis.pvalue"          
          striped
        >
        <strong style='color:white;'> {{ kpis.pvalue }} </strong>
        </v-progress-linear>
        <p class="text-left"><strong> {{ kpis.pvalue }} </strong> Compras</p>
        <br>

        <v-progress-linear
          color="pink"
          height="25"
          :value="kpis.evalue"          
          striped
        >
        <strong style='color:white;'> {{ kpis.evalue }} </strong>
        </v-progress-linear>
        <p class="text-left"><strong> {{ kpis.evalue }} </strong> Gastos</p>
        <br>

        <!-- <v-progress-circular
            :size="100"
            :width="15"
            :value="value"
            color="teal"
          >
            {{ value }}
          </v-progress-circular> -->
      </div>
    </v-card-text>
  </v-card>     
 </div>
</template>

<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
// import baseApi from '@/services/baseApi';

export default{
  name: "kpis",
  created(){
    this.getBotones()    
  },
  computed:{
    ...mapState(['currentUser','kpis'])
  },
  mounted () {
      // this.interval = setInterval(() => {
      //   if (this.value === 100) {
      //     return (this.value = 0)
      //   }
      //   this.value += 10
      // }, 1000)
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    async getBotones(){   
      try{        
          //console.log(this.sword2);       
          this.showLoading({titulo:"Actualizando Kpis",color:"warning"})  
          await this.$store.dispatch('loadKpis',this.attribute);          
        }catch(error){
          //console.log(error);
        }finally{
            this.hiddenLoading()
            //this.$swal("Genial!", this.rdesc, this.rtitle);
        }             
    }    
  },
  data(){
      return{
        attribute: {sword: '',limit: 27, date_start:'',date_end:''},
        // interval: {},
        // kpisInfo:{                        
          // qvalue:0,
          // svalue:0,
          // pvalue:0,
          // evalue:0
        // }
      }
  },
  beforeDestroy () {
      // clearInterval(this.interval)
  }, 
}
</script>