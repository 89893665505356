
<template>
<div>
    <!-- Busqueda SalesOrder -->
    <v-row>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="expensives.length > 0">
      <span class="right"><b>{{ expensives.length }}</b> registro(s) encontrados <br></span>
      <br><br>
        <!-- Tabla Salida -->
        <template>
          <!-- height="700px" -->
          <v-simple-table
            fixed-header
            dense
          >
            <template v-slot:default>
              <thead>
                <tr><th class="text-left" v-for="thead in theader" :key="thead.id">{{thead.text}}</th></tr>
              </thead>
              <tbody>
                <!-- v-for="row in subfamilies" -->
                <tr
                  v-for="(row,index) of expensives"
                  :key="row.id"
                >
                <td>
                  <!-- 
                  <button v-on:click="deleteFriend(row.id, i)">x</button>
                  -->
                  <v-btn v-on:click="editRow = row.id" icon>
                      <v-icon>edit</v-icon>
                  </v-btn> 
                  <v-btn
                        v-if="row.notes != null && row.notes != ''"
                              color="red lighten-2"
                              icon
                              @click="getNote(row.notes)" 
                            >
                            <v-icon>note</v-icon>
                  </v-btn>                         
                </td>             
                  <td>
                    <div v-if="editRow === row.id">
                      <v-text-field
                        v-model="row.branch_id"
                        v-on:keyup.13="updateRow(row)"
                        label="Sucursal ID"       
                        placeholder="Sucursal ID"
                      ></v-text-field>
                      <v-autocomplete
                              v-model="row.categoryexpense_id"
                              :items="categoryExpensives"
                              item-text="name"
                              item-value="id"
                              label="Selecciona una Categoría"  
                              outlined          
                      ></v-autocomplete>
                      <v-text-field
                        v-model="row.price"
                        v-on:keyup.13="updateRow(row)"
                        label="Precio"       
                        placeholder="Precio"
                      ></v-text-field>      
                      <v-col cols=12 sm=12 xs=12 md=4 lg=4>
                        <v-switch
                          v-model="row.iva"
                          label="IVA"
                        ></v-switch>             
                      </v-col> 
                      <v-text-field
                        v-model="row.date_buy"
                        v-on:keyup.13="updateRow(row)"
                        label="Fecha de Gasto"       
                        placeholder="Fecha de Gasto"
                      ></v-text-field>      
                      <v-text-field
                        v-model="row.notes"
                        v-on:keyup.13="updateRow(row)"
                        label="Notas"       
                        placeholder="Notas"
                      ></v-text-field>
                      <v-btn v-on:click="updateRow(row)" class="green">Actualizar</v-btn>
                      <v-btn v-on:click="editRow=false " class="deep-dark">cerrar</v-btn>
                    </div>
                    <div v-else>
                      {{index+1}}
                    </div>        
                  </td>
                  <td>{{row.id}}</td>
                  <td>
                    <div v-if="row.project_id!=null">{{row.projectfolio }} / {{row.project_id }} </div>  
                    <div v-else>
                          <v-chip
                            class="ma-2"
                            color="deep-orange"
                            label
                            text-color="white"
                            >
                              <v-icon left>
                                mdi-label
                              </v-icon>
                              Sin Folio
                            </v-chip>
                    </div>  
                      
                    <v-btn v-on:click="editRowProjectID = row.id" icon>
                          <v-icon>edit</v-icon>
                    </v-btn>
                    <div v-if="editRowProjectID === row.id">
                        <v-text-field
                                      class="my-5 py-0"
                                      v-model="row.project_id"    
                                      v-on:keyup.13="updateRowProject(row)"
                                      label="Folio Proyecto"
                                      placeholder="Escribe un folio"
                                      rounded
                                      outlined
                        ></v-text-field>
                        <v-btn v-on:click="updateRowProject(row)" class="green">Actualizar</v-btn>
                        <v-btn v-on:click="editRowProjectID=false" class="deep-black">Cerrar</v-btn>
                    </div>       
                  </td>
                  <td>{{row.branchname}}</td>
                  <td>{{row.categoryexpensename}}</td>
                  <td>{{row.price | currency }} </td>
                  <td>{{row.iva_expenses | currency }} </td>
                  <td>{{row.total_expenses | currency }} </td>                  
                <td>{{formatDate(row.date_buy)}}</td>     
                <td>{{formatDate(row.created_at)}}</td>     
                <td>{{formatDate(row.updated_at)}}</td>  
                
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <!-- FIN table -->                                    
    </v-col>
    <v-col cols=12 sm=12 md=12 lg=12
      v-else
    >
      <v-alert
          dense
          outlined
          type="error"
      >
          No hay registros encontrados
      </v-alert>
    </v-col>
  </v-row>
  <!-- FIN Busqueda SalesOrder -->  

  <!-- DIALOG NOTAS -->
<div class="text-center">
            <v-dialog
              v-model="dialogNotes"
              width="30%"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Notas
                </v-card-title>
                <v-card-text class="my-5">
                  {{notes}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogNotes = false"
                  >
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    <!-- FIN DIALOG NOTAS -->

</div>
</template>

<script>
import{ mapMutations,mapState } from "vuex";
// import{ mapMutations,mapState,mapGetters } from "vuex";
import baseApi from '@/services/baseApi';
// import router from '@/router';
import Dayjs from 'dayjs';
import es from "dayjs/locale/es"




export default {
  props:{
    expensives:{
      type:Object,
      required:true
    }
  },
  computed:{
    //'products'
      ...mapState(['categoryExpensives','currentUser'])
  },
  components: {    

  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]), 
    formatDate(dateString) {
            Dayjs.locale(es)
            const date = Dayjs(dateString);
            return date.format('dddd MMMM D, YYYY')
    },
    async getData(){
      try{        
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        // this.$store.dispatch('loadCustomers',this.attribute);
        this.$store.dispatch('loadCategoryExpensives','All');        
        // this.$store.dispatch('loadCurrencies',this.attribute);
        // this.$store.dispatch('loadSuppliers',this.attribute);
      }catch(error){
        //console.log(error);
        this.$swal("Error!", "Necesita ingresar sus credenciales", "error");
      } finally{
        this.hiddenLoading()
      } 
    },
    getNote(note){
      // console.log('Producto ID:'+ProductID);
      this.notes=note;
      // alert(this.productid);
      this.dialogNotes =true;  
    },  
    url: function (href){
      return 'detallecompra?purchaseid='+ href
    }, 
    urlCustomer: function (href){
        return 'detalleventascliente?customerid='+ href
    },
    updateRow(row) {      
      try{        
          this.showLoading({titulo:"Actualizando información",color:"warning"})                            
          baseApi().post(`/gastos/update/${row.id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              // this.$swal("Genial!","", "success");
              this.$swal("Genial!",response.data.message, "success");
            }else{
              this.$swal("Error!",response.data.message, "error");
            }                                    
            // console.log("COTIZACION: "+response.data.message);
        });                                                                                           
          // console.log(datos);
        }catch(error){
          console.log(error);
        }  finally{
          this.hiddenLoading()
          // this.$swal("Genial!", "Se actualizó la información", "success");
        } 
    },
    updateRowProject(row){
      try{        
        this.showLoading({titulo:"Accediendo información",color:"warning"})                
        baseApi().post(`/gastos/updateFolio/${row.project_id}`,JSON.stringify(row)).then((response)=>{
            if(!response.data.error){                            
              this.$swal("Genial!",response.data.message, "success");
              // this.getReturn();
            }else{
              // this.$swal("Error!",response.data.message, "error");
              this.$swal("Error!",response.data.message+': <br/>'+JSON.stringify(response.data.validate), "error"); 
            }                                    
            console.log("RETURN: "+response.data.message);
        });         
      }catch(error){
        console.log(error);        
      }  finally{
        this.hiddenLoading()
        // this.$swal("Genial!", "Se actualizó la información", "success");
      } 
    }
    
  },
  destroyed() {
    console.log("Destruido ExpensiveItem");
  },
  mounted(){
    console.log("Montado ExpensiveItem");
    // if (this.productid>0) {
      this.getData()
      
    // }
  },
  data(){
    return{
      attribute: {sword: '',limit: 500},
      dialogNotes:false,
      editRow:null,
      editRowProjectID:null,
      customer_id:77,
      itemsStatus: [
          'Completado',
          // 'Facturado',
          'Proceso'
      ],  
      notes:'',
   
      theader: [
        { text: 'Acción', value: 'canasta_basica' },
        { text: '#', value: 'index' },
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text:'Folio Proyecto',value:''},
        { text: 'Sucursal', value: 'branchname' },
        { text: 'Categoria', value: 'categoryexpensename' },
        { text: 'Price', value: 'price' },
        { text: 'Iva', value: 'iva' },
        { text: 'Total', value: 'total' },
        { text: 'Gasto', value: 'date_buy' },
        { text: 'Registro', value: 'created_at' },
        { text: 'Actualización', value: 'updated_at' },    
      ],
    }
  }
}
</script>